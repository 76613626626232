import Footer from "../components/footer/Footer";
import {StoreContext} from "../stores/StoreLoader";
import dynamic from "next/dynamic";
import {ErrorBoundary, rollbarConfig} from "../components/utilities/ErrorBoundary";
import {Provider} from '@rollbar/react';
import {getQueries} from "../utils/SchoolBlocksUtilities";
import {useContext} from "react";
import SafeRouter from "../components/utilities/SafeRouter";
import {withoutLocaleInURI} from "../internationalization/i18nURI";
import URI from 'urijs';
import {NotificationContainer as ReactNotificationContainer} from '../components/notifications/NotificationContainer';
import ClickableLink from "../components/utilities/ClickableLink";
import classNames from "classnames";
import {observer} from "mobx-react";
import {IView} from "../stores/InterfaceStore";
import Header from "../components/header/Header";
import {useHeroTerrace} from "../hooks/useHeroTerrace";
import {useGridType} from "../hooks/useGridType";
import {GridTypes} from "./MainGridPage";
import {MobileNavFooter} from "../components/header/navigation/MobileNav/MobileNavFooter";
import {shouldShowBackgroundImage} from "../stores/StyleStore";

const pages = {
    [IView.DEFAULT]: dynamic(() => import("./MainGridPage")),
    [IView.ALPHA]: dynamic(() => import("./AlphaCollectionPage")),
    [IView.MAP]: dynamic(() => import("./MapFixedCollectionPage")),
    [IView.CLASSROOM]: dynamic(() => import("./MainGridPage")),
    [IView.CALENDAR]: dynamic(() => import("./CalendarPage")),
    [IView.MYCALENDAR]: dynamic(() => import("./CalendarPage")),
    [IView.PAGE]: dynamic(() => import("./PageBlockPage/PageBlockPage")),
    [IView.FEED]: dynamic(() => import("./FeedGridPage")),
    [IView.SEARCH]: dynamic(() => import("./SearchPage/SearchPage")),
    [IView.ERROR]: dynamic(() => import("./ErrorPage")),
    [IView.LOGIN]: dynamic(() => import("./LoginPage")),
    [IView.USER_SETTINGS]: dynamic(() => import("./UserSettings/UserSettingsPage")),
    [IView.ORGANIZATION_SETTINGS]: dynamic(() => import("./OrganizationSettings/OrganizationSettings")),
    [IView.ORGANIZATION_PAGE]: dynamic(() => import("./ViewOrganization")),
    [IView.SCHOOLFEED_PAGE]: dynamic(() => import("./SchoolFeedFullPage")),
    [IView.SPLAINER_PAGE]: dynamic(() => import("../../components/components/schoolfeed/SchoolFeedSplainerPage")),
    [IView.NOTIFICATION]: dynamic(() => import("./ViewNotificationPage")),
    [IView.DASHBOARD]: dynamic(() => import("./UserDashboard/UserDashboardPage")),
    [IView.CONTENT_ITEM]: dynamic(() => import("./ContentItemPage")),
}

const HeaderShortcuts = dynamic(() => import("../components/header/shortcuts/HeaderShortcuts").then(module => module.HeaderShortcuts));
const WebAppInstallPrompt = dynamic(() => import("../components/header/WebAppInstallPrompt").then(module => module.WebAppInstallPrompt));
const NotificationContainer = dynamic(() => import("../components/blocks/SchoolBlocks/Notification/NotificationContainer"));
const WelcomePage = dynamic(() => import("./WelcomePage"));
const AdminMenu = dynamic(() => import("../components/misc/AdminMenu"));
const SchoolFeedWidget = dynamic(() => import("../components/schoolfeed/SchoolFeedWidget"));

const showAdminMenu = [IView.DEFAULT, IView.ALPHA, IView.MAP, IView.CLASSROOM, IView.CALENDAR, IView.PAGE, IView.ORGANIZATION_PAGE];

export const useShowHomepage = (() => {
    const {organizationStore, interfaceStore} = useContext(StoreContext);
    const queries = getQueries(interfaceStore.currentFullUrl || "");
    return (interfaceStore.view === IView.DEFAULT &&
        organizationStore.organization.json_data?.settings?.appearance?.showWelcomePage &&
        URI(withoutLocaleInURI(interfaceStore.currentFullUrl || "")).path() === "/" &&
        (!queries.showHomepage || queries.showHomepage === "false")) as boolean;
})

export const SchoolBlocksPageWrapper = observer((props: {
    view: IView,
    notifications: IAlert[],
    [key: string]: any,
}): JSX.Element => {

    const {userStore, organizationStore, interfaceStore, styleStore} = useContext(StoreContext);

    function shouldShowFade() {
        // We want to show the background fade for all pages that are allowed to have a
        // background image, unless the client chooses Floating Blocks theme
        const validViewsFloatingBlocks = [IView.SCHOOLFEED_PAGE, IView.SEARCH];

        // If the theme is not Floating Blocks, check to ensure the page is not a Collection Page, and return true if
        // there is a background image
        if (styleStore.themeID !== "2") {
            return shouldShowBackgroundImage(interfaceStore.view) && !!organizationStore.currentOrganization.backgroundImage;
        } // If the theme is Floating Blocks, check if the view is either SchoolFeed, or Search and return true if
          // there is a background image
        else if (validViewsFloatingBlocks.includes(props.view)) {
            return !!organizationStore.currentOrganization.backgroundImage;
        }
        return false;
    }

    const currentOrgGridType = useGridType();
    const titleOrgGridType = useGridType({useTitleOrg: true});

    const showSchoolFeedWidget = currentOrgGridType !== GridTypes.terraced &&
        (organizationStore.organization.json_data?.settings?.appearance?.showSchoolFeedWidget === "everyone" ||
            (organizationStore.organization.json_data?.settings?.appearance?.showSchoolFeedWidget === "admins" && userStore.isAdmin)) && props.view !== IView.SCHOOLFEED_PAGE;

    let notifications = props.notifications;
    if (props.view === IView.NOTIFICATION) {
        // if user is here to view a notification, don't show any others
        notifications = [];
    }

    let content;
    const showHomePage = useShowHomepage()
    if (showHomePage) {
        content = <div id={"welcome-page-container"}>
            <WelcomePage handleViewHomepage={async function () {
                await SafeRouter.setQuery("showHomepage", "true");
            }}/>
        </div>
    } else {
        let ContentComponent = pages[props.view];

        if (!ContentComponent) {
            ContentComponent = pages.error;
        } else if (props.view === IView.ORGANIZATION_SETTINGS && !userStore.isAdmin) {
            ContentComponent = pages[IView.LOGIN];
        }

        let isIOS, isAndroidChrome;
        if (interfaceStore.ExecutionEnvironment.canUseDOM) {
            if (/iPad|iPhone|iPod/g.test(navigator.userAgent) && !window.MSStream) {
                isIOS = true;
                isAndroidChrome = false;
            } else if (/Android.*Chrome/g.test(navigator.userAgent) && !window.MSStream) {
                isIOS = false;
                isAndroidChrome = true;
            } else {
                isIOS = false;
                isAndroidChrome = false;
            }
        }

        const wrapperClassName = classNames({
            "isIOS": isIOS,
            "isAndroidChrome": isAndroidChrome,
            "isNotAndroidChrome": !isAndroidChrome,
            "isNotIOS": !isIOS,
            "sb-theme-simple_and_clean": styleStore.themeID === "1",
            "sb-theme-floating_blocks": styleStore.themeID === "2",
            "sb-theme-minimal": styleStore.themeID === "3",
            "sb-terraced-grid": currentOrgGridType === GridTypes.terraced && props.view === IView.DEFAULT,
            "sb-title-org-packery": titleOrgGridType === GridTypes.packery,
            "sb-title-org-terraced": titleOrgGridType === GridTypes.terraced,
        })

        const fadeBackgroundClassName = classNames({
            "fade-background": shouldShowFade(),
        })

        const heroTerrace = useHeroTerrace();
        const displayHero = !!(currentOrgGridType === GridTypes.terraced &&
            props.view === IView.DEFAULT && heroTerrace);

        content = <div className={wrapperClassName}>
            <div className={fadeBackgroundClassName}>
                <div className={"sb-navigation-shown"}>
                    <div id={"sb-content-canvas"}>
            <span id="skiptocontent">
                <ClickableLink href="#sb-main-content" tabIndex={2}>skip to main content</ClickableLink>
                {showSchoolFeedWidget && <ClickableLink href={(interfaceStore.breakpoint === "break-point-lg") ? "#schoolfeed" : "#SchoolFeedMobileNav"} tabIndex={2}
                                                        onClick={(interfaceStore.breakpoint === "break-point-lg") ? () => interfaceStore.toggleSchoolFeedWidgetOpen() : undefined}>
                    skip to schoolfeed
                </ClickableLink>}
                <ClickableLink href={(interfaceStore.breakpoint === "break-point-lg") ? "#navigation" : "#MobileNavigationMenu"} tabIndex={2}>skip to navigation</ClickableLink>
            </span>
                        <ReactNotificationContainer/>
                        {organizationStore.organization.json_data?.settings?.webapp?.enabled && (interfaceStore.view !== IView.SPLAINER_PAGE) && <ErrorBoundary>
                            <WebAppInstallPrompt />
                        </ErrorBoundary>}
                        {notifications.length > 0 && <ErrorBoundary>
                            <NotificationContainer notifications={notifications}
                                                   shouldShowOverlay={true}
                                                   heroIsEnabled={displayHero}
                                                   key={organizationStore.currentOrganization.id}/>
                        </ErrorBoundary>}
                        {interfaceStore.view === IView.DEFAULT && <ErrorBoundary>
                            <HeaderShortcuts />
                        </ErrorBoundary>}
                        <ErrorBoundary>
                            <Header displayHero={displayHero} key={organizationStore.currentOrganization.id}/>
                        </ErrorBoundary>
                        {notifications.length > 0 && <ErrorBoundary>
                            <NotificationContainer notifications={notifications}
                                                   shouldShowBanner={true}
                                                   key={organizationStore.currentOrganization.id}/>
                        </ErrorBoundary>}
                        <ErrorBoundary>
                            <ContentComponent {...props} />
                        </ErrorBoundary>
                        {showSchoolFeedWidget && <ErrorBoundary>
                            <SchoolFeedWidget organization_id={organizationStore.currentOrganization.id}/>
                        </ErrorBoundary>}
                        <ErrorBoundary>
                            <Footer/>
                            <MobileNavFooter />
                        </ErrorBoundary>
                        {userStore.editor && showAdminMenu.includes(props.view) && <ErrorBoundary>
                            <AdminMenu key={interfaceStore.currentFullUrl} />
                        </ErrorBoundary>}
                    </div>
                </div>
            </div>
        </div>
    }

    if (userStore.id) {
        rollbarConfig.payload.person = {
            id: userStore.id,
        }
    }

    return <Provider config={rollbarConfig}>
        {content}
    </Provider>
})
